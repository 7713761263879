<template>
  <b-row>
    <b-col cols="12">
      <h2>Inquilinos</h2>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <div class="col-12 mt-16">
      <datatable
        :items="items"
        :fields="fields"
        id="user-departaments"
        @changePerPage="perPageChanged"
      >
        <template slot="filters">
          <b-col cols="3" class="my-3">
            <b-button
              variant="primary"
              class="btn-ghost"
              v-b-modal.modal-add-departament-admin
            >
              Añadir nuevo inquilino
            </b-button>
          </b-col>
        </template>

        <template slot-scope="{ item }" slot="actions">
          <b-row class="actions-icons">
            <!-- <span title="show" @click="showUser(  item.dni  )">  <i class="iconly-Light-Show"></i>  </span> -->
            <span
              title="editar"
              @click="editarInquilino(item.uuid)"
              :id="'_show_editar' + item.uuid"
            >
              <i class="iconly-Light-Edit mr-10"></i>
            </span>
            <span
              title="eliminar"
              @click="showDeleteConfirmation(item.uuid)"
              :id="'_show_eliminar' + item.uuid"
            >
              <i class="iconly-Light-Delete mr-10"></i>
            </span>
            <div
              v-if="item.is_principal"
              :key="'principal_' + item.uuid"
              title="Principal"
              :id="'_modal_inquilino' + item.uuid"
            >
              <img
                class="mr-10"
                :src="require('@/assets/icons/user.svg')"
                alt="Usuario Principal"
              />
            </div>
            <div
              v-else
              :key="'asignar_' + item.uuid"
              title="Asignar como principal"
              @click="asignarInquilino(item.uuid)"
              :id="'_modal_inquilino' + item.uuid"
            >
              <img
                class="mr-10"
                :src="require('@/assets/icons/userplus.svg')"
                alt="Asignar como Principal"
              />
            </div>
          </b-row>

          <b-tooltip :target="'_show_button_' + item.uuid" triggers="hover">
            Ver
          </b-tooltip>

          <b-tooltip :target="'_show_editar' + item.uuid" triggers="hover">
            Editar
          </b-tooltip>

          <b-tooltip :target="'_show_eliminar' + item.uuid" triggers="hover">
            Eliminar
          </b-tooltip>
          <b-tooltip
            v-if="item.is_principal"
            :target="'_modal_inquilino' + item.uuid"
            triggers="hover"
          >
            Usuario principal
          </b-tooltip>
          <b-tooltip
            v-if="!item.is_principal"
            :target="'_modal_inquilino' + item.uuid"
            triggers="hover"
          >
            Asignar como Inquilino Principal
          </b-tooltip>
        </template>

        <template slot="pagination">
          <b-pagination
            align="end"
            :total-rows="totalRows"
            :per-page="totalRowsPerpage"
            @change="pageChanged"
          ></b-pagination>
        </template>
      </datatable>
    </div>

    <b-modal
      size="xl"
      id="modal-add-departament-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-add-departament-admin"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Añadir nuevo inquilino</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <b-col cols="col-12 mt-16">
            <b-col cols="12">
              <b-input-group class="mt-3">
                <b-form-input
                  v-model="searchUser.email"
                  @input="validateEmail"
                  :class="{ 'is-invalid': emailError }"
                  placeholder="escriba un correo"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    @click.prevent="SearchUserEmail"
                    class="btn btn-ghost btn-primary"
                    :disabled="emailError || !searchUser.email"
                    >buscar</b-button
                  >
                </b-input-group-append>
              </b-input-group>
              <div v-if="emailError" class="invalid-feedback d-block">
                {{ emailError }}
              </div>
            </b-col>
          </b-col>

          <b-col cols="6 pl-12"> </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- Modal de confirmación de eliminación -->
    <b-modal
      id="modal-delete-confirmation"
      title="eliminar"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="modal-delete-confirmation"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Confirmar eliminación</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <b-row>
        <b-col cols="12">
          <p class="text-center">
            ¿Estás seguro de que deseas eliminar esta reservacion?
          </p>
        </b-col>

        <b-col cols="6" class="pr-2">
          <b-button variant="danger" block @click="confirmDelete">
            Sí, eliminar
          </b-button>
        </b-col>

        <b-col cols="6" class="pl-2">
          <b-button
            variant="none"
            block
            @click="$bvModal.hide('modal-delete-confirmation')"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!--Edit inquilino -->

    <b-modal
      id="modal-edit-inquilino"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Editar inquilino</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre completo">
              <b-form-input
                v-model="inquilinoEdit.fullName"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Email">
              <b-form-input
                v-model="inquilinoEdit.email"
                type="email"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Teléfono">
              <imask-input
                class="form-control"
                :value="inquilinoEdit.phone"
                v-imask="phoneMask"
                :unmask="true"
                @complete="onCompletePhone"
                placeholder="+56 000 000 000"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Tipo de Documento">
              <b-form-select
                v-model="inquilinoEdit.dni_type_uuid"
                :options="DniType"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="RUT">
              <b-form-input
                v-model="inquilinoEdit.dni"
                type="text"
                maxlength="14"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Fecha de nacimiento">
              <imask-input
                class="form-control"
                :value="inquilinoEdit.birth"
                v-imask="maskDob"
                :unmask="true"
                @complete="onCompleteDob"
                placeholder="día/mes/año"
              />
            </b-form-group>
          </b-col>

          <!-- <b-col cols="12" class="mt-8 mb-8">
            <b-form-group label="Address">
              <b-form-textarea
                v-model="inquilinoEdit.address"
              ></b-form-textarea>
            </b-form-group>
          </b-col> -->

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="saveEditInquilino">
              Edit
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-edit-inquilino')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- crear inquilino-->
    <b-modal
      id="modal-create-admin"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Crear nuevo inquilino</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form @submit.prevent="CreateNewAdmin">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Género">
              <b-form-select
                v-model="createAdmin.gender"
                :options="[
                  { value: 'Male', text: 'Hombre' },
                  { value: 'Female', text: 'Mujer' },
                  { value: 'Other', text: 'Otros' },
                ]"
                required
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Primer nombre">
              <b-form-input
                v-model="createAdmin.name"
                type="text"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Segundo nombre">
              <b-form-input
                v-model="createAdmin.name2"
                type="text"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Primer apellido">
              <b-form-input
                v-model="createAdmin.last_name"
                type="text"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Segundo apellido">
              <b-form-input
                v-model="createAdmin.last_name2"
                type="text"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Tipo de Documento">
              <b-form-select
                v-model="createAdmin.dni_type_uuid"
                :options="DniType"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            v-if="
              createAdmin.dni_type_uuid == 4 || createAdmin.dni_type_uuid == 3
            "
          >
            <b-form-group label="RUT">
              <imask-input
                class="form-control"
                :value="createAdmin.dni"
                v-imask="dniMask"
                :unmask="true"
                @complete="onCompleteDni"
                placeholder="00.000.000-00"
                required
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            v-if="
              createAdmin.dni_type_uuid != 4 && createAdmin.dni_type_uuid != 3
            "
          >
            <b-form-group label="Documento">
              <b-form-input
                v-model="createAdmin.dni"
                type="text"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Correo electrónico">
              <b-form-input
                v-model="findSearchUser"
                type="text"
                readonly
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Teléfono">
              <imask-input
                class="form-control"
                :value="createAdmin.phone"
                v-imask="phoneMask"
                :unmask="true"
                @complete="onCompletePhone"
                placeholder="+56 000 000 000"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Fecha de nacimiento">
              <imask-input
                class="form-control"
                :value="createAdmin.birth"
                v-imask="maskDob"
                :unmask="true"
                @complete="onCompleteDob"
                placeholder="día/mes/año"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <span class="hp-caption text-black-80 hp-text-color-dark-30 pr-8">
              ¿ Es un inquilino principal?
            </span>

            <b-form-checkbox
              switch
              size="lg"
              v-model="createAdmin.is_principal"
              value="true"
            ></b-form-checkbox>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="CreateNewAdmin">
              Crear
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-create-admin')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>
    <!-- crear inquilino ya existente-->
    <b-modal
      id="modal-create-admin-exist"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Crear nuevo inquilino</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>
      <h4>¿Está de acuerdo de asociarlo a este departamento ?</h4>
      <form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre">
              <b-form-input
                v-model="users_exist.name"
                type="text"
                :disabled="true"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Apellido">
              <b-form-input
                v-model="users_exist.last_name"
                type="text"
                :disabled="true"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="CreateNewAdminExist">
              Agregar
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-create-admin-exist')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BCard,
  BTable,
  BBadge,
  BButton,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormSelectOption,
  BFormDatepicker,
  BCalendar,
  BPagination,
} from "bootstrap-vue";

import datatable from "@/layouts/components/datatable/Datatable.vue";
import sweet from "@/logic/functions/sweetAlert";
import users from "@/logic/functions/users";
import departamentsUser from "@/logic/functions/departamentsUser";
import departaments from "@/logic/functions/departaments";
import dni from "@/logic/functions/dni";
import Locations from "@/logic/functions/Locations";
import validate_dni from "@/logic/functions/users/validate_dni";
import { IMaskComponent, IMaskDirective, useIMask } from "vue-imask";

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BFormSelectOption,
    BFormDatepicker,
    BCalendar,
    BPagination,
    datatable,
    "imask-input": IMaskComponent,
  },
  directives: {
    imask: IMaskDirective,
  },
  data() {
    return {
      fields: [
        { key: "user_uuid.name", label: "Nombre" },
        { key: "user_uuid.dni", label: "Rut" },
        { key: "user_uuid.email", label: "Email" },
        { key: "user_uuid.phone", label: "Teléfono" },
        { key: "actions", label: "Acciones" },
      ],
      Actions: [
        {
          Asd: "Asd",
        },
      ],
      items: [],
      codeText: "",
      codeActive: false,
      codeActiveClass: false,
      createAdmin: {
        dni_type_uuid: 3,
      },
      dniMask: {
        mask: "00.000.000-{[*]}",
        lazy: false,
      },
      maskDob: {
        mask: "00/00/0000",
        lazy: false,
      },
      phoneMask: {
        mask: "{+56} 000 000 000",
        lazy: false,
      },
      addNewInquilino: {},
      inquilinoEdit: {},
      findSearchUser: null,
      DniType: [],
      cityType: [],
      dniIsValid: false,
      paginationData: {},
      totalRows: 0,
      totalRowsPerpage: 5,
      currentPage: 0,
      searchUser: {
        email: "",
      },
      emailError: null,
      is_principal: false,
      users_exist: [],
    };
  },
  departaments: {},
  methods: {
    async asignarInquilino(departmentUserUuid) {
      try {
        await departamentsUser.assignPrincipal(departmentUserUuid, {
          role_id: 3,
          is_principal: true,
          nro: this.buildingNumber,
        });

        // Actualiza la lista después de asignar
        await this.ListUserDepartaments();

        sweet.ToastMsg("", "success", "Usuario asignado como principal");
      } catch (error) {
        console.error("Error al asignar como principal:", error);
        sweet.ToastMsg("", "error", "Error al asignar como principal");
      }
    },

    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.searchUser.email)) {
        this.emailError = "Por favor, ingrese un correo electrónico válido.";
      } else {
        this.emailError = null;
      }
    },
    onCompleteDni(e) {
      let dni = e.detail._value.replace(/\./g, "");
      if (validate_dni.validaRut(dni)) {
        this.dniIsValid = true;
        this.createAdmin.dni = e.detail._value;
        e.target.style.border = "none";
      } else {
        this.dniIsValid = false;
        e.target.style.border = "3px solid #FF0000";
      }
    },
    onCompleteDob(e) {
      this.inquilinoEdit.birth = e.detail._value;
      this.createAdmin.birth = e.detail._value;
    },
    onCompletePhone(e) {
      this.inquilinoEdit.phone = e.detail._value;
      this.createAdmin.phone = e.detail._value;
    },
    showUser(id) {},
    formatNUll(data) {
      return data == null || data == "" ? "" : data;
    },
    formatDateEdit(dateStr) {
      const [year, day, month] = dateStr.split("-");
      return `${month}-${day}-${year}`;
    },
    editarInquilino(dni) {
      this.inquilinoEdit = this.items.find((item) => {
        return item.uuid == dni;
      });
      this.inquilinoEdit = this.inquilinoEdit.user_uuid;
      this.inquilinoEdit.birth = this.formatDateEdit(this.inquilinoEdit.birth);
      this.inquilinoEdit.fullName =
        this.formatNUll(this.inquilinoEdit.name) +
        " " +
        this.formatNUll(this.inquilinoEdit.name2) +
        " " +
        this.formatNUll(this.inquilinoEdit.last_name) +
        " " +
        this.formatNUll(this.inquilinoEdit.last_name2);
      // Elimina espacios innecesarios
      this.inquilinoEdit.fullName = this.inquilinoEdit.fullName.trim();

      this.$bvModal.show("modal-edit-inquilino");
    },
    anadirInquilino(dni) {
      this.items.push(
        this.loadNewUsers.find((item) => {
          return item.dni == dni;
        })
      );

      this.$bvModal.hide("modal-add-departament-admin");
      sweet.ToastMsg("", "success", "usuario añadido");
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.eliminarInquilino(this.itemToDelete);
        this.$bvModal.hide("modal-delete-confirmation");
      }
    },
    showDeleteConfirmation(uuid) {
      this.itemToDelete = uuid;
      this.$bvModal.show("modal-delete-confirmation");
    },
    async eliminarInquilino(dni) {
      let items = this.items;

      departamentsUser
        .assing_delete(dni)
        .then((response) => {
          const index = items.findIndex((item) => item.uuid === dni);
          if (~index) {
            items.splice(index, 1);
          }
          sweet.ToastMsg("", "success", "inquilino eliminado");
        })
        .catch((error) => {});
    },
    saveEditInquilino() {
      this.$bvModal.hide("modal-edit-inquilino");
      sweet.ToastMsg("", "success", "inquilino editado");
    },
    listCityType() {
      Locations.listCity()
        .then((response) => {
          Object.entries(response.data.data).forEach((entry) => {
            const [key, value] = entry;
            this.cityType.push({ value: value.uuid, text: value.name });
          });
        })
        .catch((error) => {});
    },
    listDniType() {
      dni
        .list()
        .then((response) => {
          Object.entries(response.data.data).forEach((entry) => {
            const [key, value] = entry;
            this.DniType.push({ value: value.uuid, text: value.name });
          });
        })
        .catch((error) => {});
    },
    assignUser(dataUser) {
      let adminUsers = this.items;
      let departamentUuid = this.$route.params.id;
      let departamentData = this.departaments;

      departamentsUser
        .assing({
          department_uuid: "" + departamentUuid + "",
          users_uuid: "" + dataUser.uuid + "",
          role_id: 3,
          nro: "" + departamentData.nro + "",
        })
        .then((responseAssign) => {
          adminUsers.push(dataUser);
          sweet.ToastMsg("", "success", "usuario asignado");
        })
        .catch((error) => {
          sweet.ToastMsg("", "error", "usuario no asignado");
        });
    },
    SearchUserEmail() {
      if (!this.emailError) {
        users
          .searchuseremail(this.searchUser.email)
          .then((response) => {
            this.users_exist = response.data;
            console.log(this.users_exist);
            if (("useremail", response.data.uuid)) {
              weet.ToastMsg("", "error", "el usuario ya existe");
              this.$bvModal.hide("modal-add-departament-admin");
              this.$bvModal.show("modal-create-admin-exist");
              this.assignUser(response.data);
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 404) {
              this.$bvModal.hide("modal-add-departament-admin");
              this.$bvModal.show("modal-create-admin");
              this.findSearchUser = this.searchUser.email;
              this.searchUser.email = "";
            } else {
              sweet.ToastMsg("", "error", "El usuario ya existe");
              this.$bvModal.hide("modal-add-departament-admin");
              this.$bvModal.show("modal-create-admin-exist");
            }
          });
      }
    },
    async getBuildingInfo() {
      await departaments
        .list({
          params: {
            department_uuid: this.$route.params.id,
          },
        })
        .then((response) => {
          this.departaments = response.data;
          this.buildingNumber = this.departaments.nro;
        })
        .catch((error) => {
          sweet.ToastMsg("", "error", "no se pudo obtener los edificios");
        });
    },
    async CreateNewAdmin() {
      try {
        this.createAdmin.dni_type_uuid == 3 ||
        this.createAdmin.dni_type_uuid == 4
          ? this.dniIsValid
          : true;

        // Validar campos requeridos
        if (
          !this.createAdmin.name ||
          !this.createAdmin.last_name ||
          !this.createAdmin.dni
        ) {
          sweet.ToastMsg(
            "",
            "error",
            "Por favor, completa todos los campos obligatorios."
          );
          return;
        }

        if (!this.dniIsValid) {
          sweet.ToastMsg("", "error", "DNI no válido");
          return;
        }

        const departamentUuid = this.$route.params.id;
        const departamentData = this.departaments;
        let UserCreateData = {
          active: true,
          is_principal: this.createAdmin.is_principal ?? false,
          address: "",
          birth: this.createAdmin.birth,
          dni: this.createAdmin.dni,
          dni_type_uuid: "" + this.createAdmin.dni_type_uuid + "",
          email: this.findSearchUser,
          gender: this.createAdmin.gender,
          last_name: this.createAdmin.last_name,
          last_name2: this.createAdmin.last_name2,
          name: this.createAdmin.name,
          name2: this.createAdmin.name2,
          nationality_id: "CL",
          password: "",
          phone: this.createAdmin.phone,
        };

        const userResponse = await users.create_user(UserCreateData);

        if (userResponse.data && userResponse.data.uuid) {
          const assignData = {
            department_uuid: "" + departamentUuid + "",
            users_uuid: "" + userResponse.data.uuid + "",
            role_id: 3,
            nro: this.buildingNumber,
            is_principal: this.createAdmin.is_principal ?? false,
          };
          const assignResponse = await departamentsUser.assing(assignData);

          await this.ListUserDepartaments();
          sweet.ToastMsg("", "success", "Usuario asignado");
          this.$bvModal.hide("modal-create-admin");
        } else {
          throw new Error("No se recibió UUID de usuario");
        }
      } catch (error) {
        sweet.ToastMsg("", "error", "Error al crear o asignar usuario");
      }
    },
    async CreateNewAdminExist() {
      try {
        const departamentUuid = this.$route.params.id;
        const departamentData = this.departaments;

        const assignData = {
          department_uuid: "" + departamentUuid + "",
          users_uuid: this.users_exist.uuid,
          role_id: 3,
          nro: this.buildingNumber,
          is_principal: this.createAdmin.is_principal ?? false,
        };
        const assignResponse = await departamentsUser.assing(assignData);

        await this.ListUserDepartaments();
        sweet.ToastMsg("", "success", "Usuario asignado");
        this.$bvModal.hide("modal-create-admin-exist");
      } catch (error) {
        sweet.ToastMsg("", "error", "Error al crear o asignar usuario");
      }
    },

    async ListUserDepartaments(page = 0, limit = 5) {
      await departamentsUser
        .list({
          params: {
            limit: this.totalRowsPerpage || limit,
            page: this.currentPage || page,
            department_uuid: this.$route.params.id,
          },
        })
        .then((response) => {
          this.items = response.data.data;
          this.totalRows = response.data.items;
          this.totalRowsPerpage = response.data.limit;
        })
        .catch((error) => {});
    },
    date_format(e) {
      if (e.length <= 12) {
        const newRut = e
          .replace(/\./g, "")
          .replace(/\-/g, "")
          .trim()
          .toLowerCase();
        const lastDigit = newRut.substr(-1, 1);
        const rutDigit = newRut.substr(0, newRut.length - 1);
        let format = "";
        for (let i = rutDigit.length; i > 0; i--) {
          const e = rutDigit.charAt(i - 1);
          format = e.concat(format);
          if (i % 3 === 0) {
            format = ".".concat(format);
          }
        }
        this.createAdmin.dni = format.concat("-").concat(lastDigit);
      }
    },
    maskdni(e) {
      var inputValue = e;

      var dni = inputValue.replace(
        /(\d{2})(\d{3})(\d{3})(\d{1})/,
        "$1.$2.$3-$4"
      );

      this.createAdmin.dni = dni;
    },
    async pageChanged(page) {
      page = page == 1 ? 0 : page - 1;
      this.currentPage = page;
      this.ListUserDepartaments(page);
    },
    async perPageChanged(perPage) {
      this.totalRowsPerpage = perPage;
      this.currentPage = 0;
      this.ListUserDepartaments(0, perPage);
    },
  },
  watch: {
    totalRowsPerpage(newValue) {
      console.log(newValue);
    },
    "$route.params.id": {
      handler: function (value) {
        this.getBuildingInfo();
      },
    },
  },
  beforeMount() {},
  mounted() {
    this.listDniType();
    this.listCityType();
    this.ListUserDepartaments();
    this.getBuildingInfo();
  },
};
</script>
