<template>
  <b-col class="hp-profile-menu py-24 px-0" style="flex: 0 0 240px">
    <div class="w-100">
      <div class="hp-profile-menu-header mt-16 mt-lg-0 text-center">
        <div class="hp-menu-header-btn mb-12 text-right">
          <!--
          <b-dropdown variant="link" no-caret toggle-class="p-0">
            <template #button-content>
              <div class="d-inline-block">
                <b-button variant="text" class="btn btn-icon-only">
                  <i
                    class="ri-more-2-line text-black-100 hp-text-color-dark-30 lh-1"
                    style="font-size: 24px"
                  ></i>
                </b-button>
              </div>
            </template>
            <b-dropdown-item href="#">Change Avatar</b-dropdown-item>
          </b-dropdown>
          -->
        </div>

        <div class="d-flex justify-content-center">
          <div class="d-inline-block position-relative">
            <div
              class="d-flex align-items-center justify-content-center rounded-circle"
              style="width: 80px; height: 80px"
            >
              <img :src="require('@/assets/img/building/building.png')" />
            </div>

            <!-- <b-badge
              pill
              class="position-absolute bg-primary text-white border-0"
            >
              12
            </b-badge> -->
          </div>
        </div>

        <h3 class="mt-24 mb-4">{{ data.name }}</h3>
        <span class="hp-p1-body">
          {{ data.dni }}
        </span>
        <div class="hp-p1-body">
          nro: {{ buildingNumber }}
        </div>
      </div>
    </div>

    <div class="hp-profile-menu-body w-100 text-left mt-48 mt-lg-0">
      <ul>
        <li class="mt-4 mb-16">
          <b-link
            :to="inquilinos"
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
              route_name == 'get-departament-admins' ? ' active' : ''
            }`"
          >
            <i class="iconly-Broken-Home mr-8"></i>
            <span>Inquilinos</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>

        <li class="mt-4 mb-16">
          <b-link
            :to="visits"
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
              route_name == 'get-departament-visits-admins' ? ' active' : ''
            }`"
          >
            <i class="iconly-Broken-Home mr-8"></i>
            <span>Visitas</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>

        <li class="mt-4 mb-16">
          <b-link
            :to="packages"
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
              route_name == 'get-departament-package-admins' ? ' active' : ''
            }`"
          >
            <i class="iconly-Broken-Home mr-8"></i>
            <span>Paquetes</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>
      </ul>
    </div>

    <div class="hp-profile-menu-footer">
      <img
        :src="require('@/assets/img/pages/profile/menu-img.svg')"
        alt="Profile Image"
      />
    </div>
  </b-col>
</template>

<script>
import {
  BCol,
  BBadge,
  BButton,
  BLink,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import departaments from "@/logic/functions/departaments";

export default {
  data() {
    return {
      data: this.building,
      departaments: {},
      buildingNumber: null,
      path: "",
      params: "",
      route_name: "",
      visits: "",
      packages: "",
    };
  },
  watch: {
    "$route.params.id": {
      handler: function (value) {
        this.getBuildingInfo();
      },
    },
  },
  props: {
    building: {
      type: Object,
    }
  },
  components: {
    BCol,
    BBadge,
    BButton,
    BLink,
    BDropdown,
    BDropdownItem,
  },
  watch: {
    $route() {
      this.getRoute();
    },
    building() {
      this.data = this.building;
    },
  },
  methods: {
    getRoute() {
      this.path = this.$route.path;
      this.params = this.$route.params;
      this.route_name = this.$route.name;

      this.inquilinos = "/admin/departament/" + this.params.id;
      this.visits = "/admin/departament/visits/" + this.params.id;
      this.packages = "/admin/departament/packages/" + this.params.id;
    },
    async getBuildingInfo() {
      await departaments
        .list({
          params: {
            department_uuid: this.$route.params.id,
          },
        })
        .then((response) => {
          this.departaments = response.data;
          this.buildingNumber = this.departaments.nro;
        })
        .catch((error) => {
          sweet.ToastMsg("", "error", "no se pudo obtener los edificios");
        });
    },
  },
  mounted() {
    this.getBuildingInfo();
  },
  beforeMount() {},
  created() {
    this.getRoute();
  },
};
</script>
