<template>
    <b-row>
      <b-col cols="12">
        <h2>Paquetes</h2>
      </b-col>
  
      <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>
  
      <div class="col-12 mt-16">
        <datatable :items="items" :fields="fields">
  
          <template slot="filters"  >
            <b-col cols="3" class="my-3">
              <b-button
              variant="primary"
              class="btn-ghost"
              v-b-modal.modal-add-document-admin
              >
              Añadir nuevo paquete 
              </b-button>
            </b-col>
          </template>
          
          <template slot-scope="{item}" slot="actions"  >
            <b-row class="actions-icons">
              <span title="ver" @click="show(  item.uuid  )" :id="'_show_button_'+item.uuid">  <i class="iconly-Light-Show"></i>  </span>
              <span title="editar" @click="edit(  item.uuid  )" :id="'_show_editar'+item.uuid"> <i class="iconly-Light-Edit mr-10"></i> </span>
              <span title="eliminar" @click="deletes(  item.uuid  )" :id="'_show_eliminar'+item.uuid"> <i class="iconly-Light-Delete mr-10"></i> </span>
              <span title="quien retiro" @click="package_receive(  item.uuid,item  )" :id="'_show_retiro'+item.uuid"> <i class="iconly-Bold-User"></i> </span>
            </b-row>

            <b-tooltip :target="'_show_button_'+item.uuid" triggers="hover">
              Ver
            </b-tooltip>

            <b-tooltip :target="'_show_editar'+item.uuid" triggers="hover">
            Editar
            </b-tooltip>

            <b-tooltip :target="'_show_eliminar'+item.uuid" triggers="hover">
              Eliminar
            </b-tooltip>

            <b-tooltip :target="'_show_retiro'+item.uuid" triggers="hover">
              Entregar paquete
            </b-tooltip>

          </template>
  
          <template  slot="pagination"  >
            <b-pagination
              align="end"
             
              :total-rows="totalRows"
              :per-page="totalRowsPerpage"
              @change="pageChanged"
            ></b-pagination>
          </template>
  
        </datatable>
      </div>
   
      <!-- create document -->
      <b-modal
        size="xl"
        id="modal-add-document-admin"
        cancel-variant="text"
        body-class="py-50"
        header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
        centered
        hide-footer
        content-class="modal-add-document-admin"
        modal-class="hp-profile-modal-1"
        @shown="clearData"
      >
        <template #modal-header="{ close }">
          <div
            class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
          >
            <h5 class="mb-0">Añadir nuevo paquete</h5>
  
            <b-button
              variant="text"
              @click="close()"
              class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
              style="min-height: 24px"
            >
              <i
                class="ri-close-line hp-text-color-dark-0 lh-1"
                style="font-size: 24px"
              ></i>
            </b-button>
          </div>
  
          <div class="divider my-0 p-0"></div>
        </template>
  
        <form inline>
          <b-row>
   
            <b-col cols="12">
              <b-form-group label="Nombre y apellido">
                <b-form-input v-model="documentCreate.name" type="text" ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Empresa">
                <b-form-input v-model="documentCreate.company" type="text" ></b-form-input>
              </b-form-group>
            </b-col>
  
            <b-col cols="12">
                <b-form-group label="Tipo de Documento">
                  <b-form-select v-model="documentCreate.dni_type_uuid " :options="DniType"></b-form-select>
                </b-form-group>
            </b-col>
  
            <b-col cols="12" v-if="documentCreate.dni_type_uuid == 4 || documentCreate.dni_type_uuid == 3">
            <b-form-group label="RUT">
              <imask-input 
                class="form-control"
                :value="documentCreate.dni"
                v-imask="dniMask"
                :unmask="true"
                @complete="onCompleteDni"
                placeholder='00.000.000-00'
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" v-if="documentCreate.dni_type_uuid != 4 && documentCreate.dni_type_uuid != 3">
            <b-form-group label="Documento">
              <b-form-input v-model="documentCreate.dni" type="text" ></b-form-input>
            </b-form-group>
          </b-col>
  
            <b-col cols="12">
              <b-form-group label="Comentario">
                <b-form-textarea v-model="documentCreate.comment" type="text" ></b-form-textarea>
              </b-form-group>
            </b-col>
  
            <b-col cols="6 pr-12">
              <b-button 
                variant="primary"
                block
                @click="submitFile"
              >
                Añadir
              </b-button>
            </b-col>
  
            <b-col cols="6 pl-12">
              <b-button
                variant="none"
                block
                @click="$bvModal.hide('modal-add-document-admin')"
              >
                Cerrar
              </b-button>
            </b-col>
            
          </b-row>
        </form>
      </b-modal>
  
      <!-- edit document -->
      <b-modal
        size="xl"
        id="modal-edit-document-admin"
        cancel-variant="text"
        body-class="py-50"
        header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
        centered
        hide-footer
        content-class="modal-edit-document-admin"
        modal-class="hp-profile-modal-1"
 
      >
        <template #modal-header="{ close }">
          <div
            class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
          >
            <h5 class="mb-0">Editar paquete</h5>
  
            <b-button
              variant="text"
              @click="close()"
              class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
              style="min-height: 24px"
            >
              <i
                class="ri-close-line hp-text-color-dark-0 lh-1"
                style="font-size: 24px"
              ></i>
            </b-button>
          </div>
  
          <div class="divider my-0 p-0"></div>
        </template>
  
        <form inline>
          <b-row>
   
            <b-col cols="12">
              <b-form-group label="Nombre">
                <b-form-input v-model="documentCreate.name" type="text" ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Empresa">
                <b-form-input v-model="documentCreate.company" type="text" ></b-form-input>
              </b-form-group>
            </b-col>
  
            <b-col cols="12">
                <b-form-group label="Tipo de Documento">
                  <b-form-select v-model="documentCreate.dni_type_uuid " :options="DniType"></b-form-select>
                </b-form-group>
            </b-col>
  
            <b-col cols="12" v-if="documentCreate.dni_type_uuid == 4 || documentCreate.dni_type_uuid == 3">
            <b-form-group label="RUT">
              <imask-input 
                class="form-control"
                :value="documentCreate.dni"
                v-imask="dniMask"
                :unmask="true"
                @complete="onCompleteDni"
                placeholder='00.000.000-00'
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" v-if="documentCreate.dni_type_uuid != 4 && documentCreate.dni_type_uuid != 3">
            <b-form-group label="Documento">
              <b-form-input v-model="documentCreate.dni" type="text" ></b-form-input>
            </b-form-group>
          </b-col>
  
            <b-col cols="12">
              <b-form-group label="Comentario">
                <b-form-textarea v-model="documentCreate.comment" type="text" ></b-form-textarea>
              </b-form-group>
            </b-col>
  
            <b-col cols="6 pr-12">
              <b-button 
                variant="primary"
                block
                @click="submitFileEdit"
              >
                Editar
              </b-button>
            </b-col>
  
            <b-col cols="6 pl-12">
              <b-button
                variant="none"
                block
                @click="$bvModal.hide('modal-edit-document-admin')"
              >
                Cerrar
              </b-button>
            </b-col>
            
          </b-row>
        </form>
      </b-modal>
      
      <!-- View document detail -->
      <b-modal
        id="modal-detail-document-admin"
        cancel-variant="text"
        body-class="py-48"
        header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
        centered
        hide-footer
        modal-class="modal-detail-document-admin"
      >
        <template #modal-header="{ close }">
          <div
            class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
          >
            <h5 class="mb-0">Ver detalle del paquete</h5>
  
            <b-button
              variant="text"
              @click="close()"
              class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
              style="min-height: 24px"
            >
              <i
                class="ri-close-line hp-text-color-dark-0 lh-1"
                style="font-size: 24px"
              ></i>
            </b-button>
          </div>
  
          <div class="divider my-0 p-0"></div>
        </template>
  
        <form>
          <b-row>
  
            <b-col cols="12">
              <b-form-group label="Nombre">
                <b-form-input v-model="documentCreate.name" type="text" :disabled="true" ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Empresa">
                <b-form-input v-model="documentCreate.company" type="text" :disabled="true" ></b-form-input>
              </b-form-group>
            </b-col>
  
            <b-col cols="12" class="mt-8">
              <b-form-group label="RUT">
                <b-form-input v-model="documentCreate.dni" type="text" :disabled="true"></b-form-input>
              </b-form-group>
            </b-col>
  
            <b-col cols="12" class="mt-8">
              <b-form-group label="Comentario">
                <b-form-textarea v-model="documentCreate.comment" type="text" :disabled="true"></b-form-textarea>
              </b-form-group>
            </b-col>
  
            <b-col cols="12" class="mt-8" v-if="documentCreate.deivery_by_name != null && documentCreate.deivery_by_dni != null">
              <span>Persona que lo recibe:</span>
              <b-form-group label="Nombre">
                <b-form-textarea v-model="documentCreate.deivery_by_name" type="text" :disabled="true"></b-form-textarea>
              </b-form-group>
              <b-form-group label="RUT">
                <b-form-textarea v-model="documentCreate.deivery_by_dni" type="text" :disabled="true"></b-form-textarea>
              </b-form-group>
            </b-col>

            <b-col cols="6 pl-12">
              <b-button
                variant="none"
                block
                @click="$bvModal.hide('modal-detail-document-admin')"
              >
                Cerrar
              </b-button>
            </b-col>
          </b-row>
        </form>
      </b-modal>
  
    <b-modal
      size="xl"
      id="modal-package-recive-by"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-package-recive-by"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Quien recibe el paquete</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
 
          
        <b-col cols="12">
          <b-form-group label="Seleccione inquilino">
            <v-select 
              v-model="documentCreate.inquilino"
              :options="inquilinos"
              :reduce="(value) => value.code"
              >
            </v-select>
          </b-form-group>
        </b-col>

        <b-col cols="6 pr-12">
          <b-button
            variant="primary"
            block
            @click="InquilinoRecibePackage"
          >
            Entregar paquete
          </b-button>
        </b-col>

        <b-col cols="6 pr-12">
          <b-button
            variant="primary"
            block
            @click="showAddRecibePackage"
          >
            Otro inquilino
          </b-button>
        </b-col>

        </b-row>
      </form>
    </b-modal>


    <!-- crear inquilino-->
    <b-modal
      id="modal-create-admin"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Crear nuevo inquilino</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form>
        <b-row>

          <b-col cols="12">
              <b-form-group label="Género"> <span class="FieldRequired">*</span>
                <b-form-select v-model="createAdmin.gender" :options="[
                  { value: 'Male', text: 'Hombre' },
                  { value: 'Female', text: 'Mujer' },
                  { value: 'Other', text: 'Otros' },
                  ]"></b-form-select>
              </b-form-group>
          </b-col>

          
          <b-col cols="12">
            <b-form-group label="Primer nombre"> <span class="FieldRequired">*</span>
              <b-form-input v-model="createAdmin.name" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Segundo nombre">
              <b-form-input v-model="createAdmin.name2" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Primer apellido"> <span class="FieldRequired">*</span>
              <b-form-input v-model="createAdmin.last_name" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Segundo apellido">
              <b-form-input v-model="createAdmin.last_name2" type="text"></b-form-input>
            </b-form-group>
          </b-col>
          
          <b-col cols="12">
              <b-form-group label="Tipo de Documento"> <span class="FieldRequired">*</span>
                <b-form-select v-model="createAdmin.dni_type_uuid " :options="DniType"></b-form-select>
              </b-form-group>
          </b-col>

          <b-col cols="12" v-if="createAdmin.dni_type_uuid == 4 || createAdmin.dni_type_uuid == 3">
            <b-form-group label="RUT"> <span class="FieldRequired">*</span>
              <imask-input 
                class="form-control"
                :value="createAdmin.dni"
                v-imask="dniMask"
                :unmask="true"
                @complete="onCompleteDni"
                placeholder='00.000.000-00'
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" v-if="createAdmin.dni_type_uuid != 4 && createAdmin.dni_type_uuid != 3">
            <b-form-group label="Documento"> <span class="FieldRequired">*</span>
              <b-form-input v-model="createAdmin.dni" type="text" ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Correo electrónico">
              <b-form-input v-model="createAdmin.email" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Clave"> <span class="FieldRequired">*</span>
              <b-form-input v-model="createAdmin.password" type="password"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Teléfono">
              <imask-input 
                class="form-control"
                :value="createAdmin.phone"
                v-imask="phoneMask"
                :unmask="true"
                @complete="onCompletePhone"
                placeholder='+56 000 000 000'
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Fecha de nacimiento">
              <imask-input 
                class="form-control"
                :value="createAdmin.birth"
                v-imask="maskDob"
                :unmask="true"
                @complete="onCompleteDob"
                placeholder='día/mes/año'
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Dirección"> <span class="FieldRequired">*</span>
              <b-form-input v-model="createAdmin.address" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
              <span class="hp-caption text-black-80 hp-text-color-dark-30 pr-8">
                Activo <span class="FieldRequired">*</span>
              </span>

              <b-form-checkbox
                switch
                size="lg"
                v-model="createAdmin.active"
                value="true"
              ></b-form-checkbox>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button
              variant="primary"
              block
              @click="addInquilinoRecibePackage"
            >
              Crear
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-create-admin')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- quien retiro paquete dos-->

    <b-modal
      size="xl"
      id="modal-package-recive-by-two"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-package-recive-by-two"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Quien recibe el paquete</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
 
          <b-col cols="12">
            <b-form-group label="Nombre y apellido">
              <b-form-input v-model="packageDeliveryReciveBy.name" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="RUT">
              <imask-input 
                class="form-control"
                :value="packageDeliveryReciveBy.dni"
                v-imask="dniMask"
                :unmask="true"
                @complete="onCompleteDni"
                placeholder='00.000.000-00'
              />
            </b-form-group>
          </b-col>
 
          <b-col cols="12 pr-12">
            <b-button
              variant="primary"
              block
              @click="addInquilinoRecibePackageTwo"
            >
              Entregar paquete
            </b-button>
          </b-col>

        </b-row>
      </form>
    </b-modal>

    </b-row>
  </template>
  
  <script>
  import {
      BCard,
      BTable,
      BBadge,
      BButton,
      BRow,
      BCol,
      BFormCheckbox,
      BFormGroup,
      BFormInput,
      BFormTextarea,
      BInputGroup,
      BInputGroupAppend,
      BFormSelect,
      BFormFile,
      BFormSelectOption,
      BFormDatepicker,
      BCalendar,
      BPagination  } from "bootstrap-vue";
  
      import datatable  from "@/layouts/components/datatable/Datatable.vue"
      import sweet from '@/logic/functions/sweetAlert';
      import packages from '@/logic/functions/packages';
      import { DateTime } from 'luxon'
      import dni from '@/logic/functions/dni';
      import { IMaskComponent, IMaskDirective, useIMask } from 'vue-imask';
      import validate_dni from '@/logic/functions/users/validate_dni';
      import departamentsUser from '@/logic/functions/departamentsUser';
      import vSelect from 'vue-select'
      import 'vue-select/dist/vue-select.css';

  export default {
    components: {
      BCard,
      BTable,
      BBadge,
      BButton,
      BRow,
      BCol,
      BFormCheckbox,
      BFormGroup,
      BFormInput,
      BFormTextarea,
      BInputGroup,
      BInputGroupAppend,
      BFormSelect, // this
      BFormSelectOption,
      BFormDatepicker,
      BCalendar, //this
      BFormFile,
      BPagination,
      datatable,
      vSelect,
      'imask-input': IMaskComponent,
   
    },
    directives: {
      imask: IMaskDirective
  },
    data() {
      return {
        fields: [
        { key: "company", label: "Empresa" },
        { key: "created_at", label: "Fecha de recepción",
        formatter: (value, key, item) => {
          return DateTime
              .fromISO(item.created_at)
              .toFormat('dd/MM/yyyy HH:mm ')+'hrs';
        }},
        { key: "actions", label: "Acciones" },
      ],
        Actions:[ ],
        items: [
        ],
        loadNewUsers: [ 
        ],
        codeText: "",
        codeActive: false,
        codeActiveClass: false,
        flags:{
          formUpload : false,
          fileUpload : false
        },
        dniMask : {
        mask: '00.000.000-{[*]}',
        lazy: false
        },
        maskDob: {
        mask: '00/00/0000',
        lazy: false
        },
        phoneMask: {
          mask: '{+56} 000 000 000',
          lazy: false
        },
        inquilinos: [],
        DniType:[],
        createSales : {},
        documentCreate : {
          dni_type_uuid : 3
        },
        packageDeliveryReciveBy: {},
        packageDeliveryUuid: null,
        searchBy: {},
        createAdmin: {},
        dniIsValid: false,
        paginationData : {},
          totalRows : 0,
          totalRowsPerpage : 5,
          currentPage : 0,
      };
    },
    methods: {
      onCompleteDni(e){
      let dni = e.detail._value.replace(/\./g,'');
        if(validate_dni.validaRut(dni)){
          this.dniIsValid = true;
          this.documentCreate.dni = e.detail._value;
          this.createAdmin.dni = e.detail._value;
          this.packageDeliveryReciveBy.dni = e.detail._value;
          e.target.style.border = "none";
        }else{
          this.dniIsValid = false;
          e.target.style.border = "3px solid #FF0000";
        }
    },
    onCompleteDob(e){
      this.createAdmin.birth =  e.detail._value
    },
    onCompletePhone(e){
      this.createAdmin.phone =  e.detail._value
    },
    clearData(){
      this.documentCreate = {};
    },
    package_receive(uuid, item){
      if(item.delivered_by != null || item.delivered_other_dni != null || item.delivered_other_name != null){
        sweet.ToastMsg('','success','Paquete ya entregado');
      }else{
        this.searchBy.email = '';
        this.packageDeliveryUuid = uuid;
        this.$bvModal.show('modal-package-recive-by');
      }
    },
    showAddRecibePackage(){
      this.$bvModal.hide('modal-package-recive-by')
      this.$bvModal.show('modal-package-recive-by-two');
    },
    async package_receive_by(deliveryData){
      await packages.update({
            "delivered_by": deliveryData.delivered_by,
            "delivered_other_dni": deliveryData.delivered_other_dni,
            "delivered_other_name": deliveryData.delivered_other_name,
          },this.packageDeliveryUuid).then((response) => {
            this.documents();
            sweet.ToastMsg('','success','Paquete entregado');
          }).catch((error) => {
            sweet.ToastMsg('','error','Paquete no entregado');
          });
    },
    async InquilinoRecibePackage(){
      if(this.documentCreate.inquilino != null){
        let deliveryData = {
        delivered_by : this.documentCreate.inquilino,
        };
        this.package_receive_by(deliveryData);
        this.$bvModal.hide('modal-package-recive-by');
      }else{
        sweet.ToastMsg('','error','Seleccione inquilino');
      }
    },
    async addInquilinoRecibePackageTwo(){
      let deliveryData = {
        delivered_other_dni : this.packageDeliveryReciveBy.name,
        delivered_other_name : this.packageDeliveryReciveBy.dni
      };
      this.package_receive_by(deliveryData);
      this.$bvModal.hide('modal-package-recive-by-two');
    },
    async addInquilinoRecibePackage(){
      this.dniIsValid = (this.createAdmin.dni_type_uuid == 3 || this.createAdmin.dni_type_uuid == 4) ? this.dniIsValid : true;
      if(this.dniIsValid){
        let departamentUuid = this.$route.params.id;
        let departamentData = this.departaments;
        let UserCreateData = {
          "active": this.createAdmin.active !== null ? this.createAdmin.active : false,//this.createAdmin.active,
          "address": this.createAdmin.address,
          "birth": this.createAdmin.birth,
          "dni": this.createAdmin.dni,
          "dni_type_uuid": ""+this.createAdmin.dni_type_uuid+"",
          "email": this.findSearchUser,//this.createAdmin.email,
          "gender": this.createAdmin.gender,
          "last_name": this.createAdmin.last_name,
          "last_name2": this.createAdmin.last_name2,
          "name": this.createAdmin.name,
          "name2": this.createAdmin.name2,
          "nationality_id": 'CL',
          "password": this.createAdmin.password,
          "phone": this.createAdmin.phone
        } 

        await users.create_user(UserCreateData).then((response) => {
    
          if(response.data){
            departamentsUser.assing({
                "department_uuid": ""+departamentUuid+"",
                "users_uuid": ""+response.data.uuid+"",
                "role_id": 3,
                "nro": ""+departamentData.nro+""
              }).then((response) => {
 
                console.log(response.data)

              })
              .catch((error) => {
                sweet.ToastMsg('','error','usuario no asignado');
              });
          
          }
    
        }).catch((error) => {
  
        });
      }

    },
      show(uuid){
  
        this.documentCreate = this.items.find(item => {
          return item.uuid == uuid
        });

        if(this.documentCreate.delivered_by != null){
          this.documentCreate.deivery_by_name = this.documentCreate.delivered_by.name;
          this.documentCreate.deivery_by_dni = this.documentCreate.delivered_by.dni;
        }else if(this.documentCreate.delivered_other_dni != null && this.documentCreate.delivered_other_name != null){
          this.documentCreate.deivery_by_name = this.documentCreate.delivered_other_name;
          this.documentCreate.deivery_by_dni = this.documentCreate.delivered_other_dni;
        }
  
        this.$bvModal.show('modal-detail-document-admin');
      },
      edit(uuid){
        this.documentCreate = {};
        this.documentCreate = this.items.find(item => {
          return item.uuid == uuid
        });
  
        this.$bvModal.show('modal-edit-document-admin')
      },
      deletes(uuid){
        let items = this.items;
        packages.delete(uuid).then((response) => {
          const index = items.findIndex(item => item.uuid === uuid)
              if (~index){
                items.splice(index, 1)
              } 
          sweet.ToastMsg('','success','Paquete eliminado');
        }).catch((error) => {
      
        });
      },
      async documents(page = 0){
        
        await packages.list({
          params:{
            'limit':5,
            'page': 0,
            'department_uuid' : this.$route.params.id
          }
        }).then((response) => {
           this.items = response.data.data
        }).catch((error) => {
            
        });
      },
      async submitFile(){
          await packages.create({
            "company": this.documentCreate.company,
            "comment": this.documentCreate.comment,
            "department_uuid": this.$route.params.id,
            "dni": this.documentCreate.dni,
            "dni_type_uuid": this.documentCreate.dni_type_uuid.toString(),
            "name": this.documentCreate.name
          }).then((response) => {
            this.documents();
            this.$bvModal.hide('modal-add-document-admin');
            sweet.ToastMsg('','success','Paquete creado');
          }).catch((error) => {
            this.flags.fileUpload = false;
            sweet.ToastMsg('','error','Paquete no creado');
          });
      },
      async submitFileEdit(){
        await packages.update({
            "company": this.documentCreate.company,
            "comment": this.documentCreate.comment,
            "department_uuid": this.$route.params.id,
            "dni": this.documentCreate.dni,
            "dni_type_uuid": this.documentCreate.dni_type_uuid.toString(),
            "name": this.documentCreate.name
          },this.documentCreate.uuid).then((response) => {
            this.documents();
            this.$bvModal.hide('modal-edit-document-admin');
            sweet.ToastMsg('','success','Paquete editado');
          }).catch((error) => {
            this.flags.fileUpload = false;
            sweet.ToastMsg('','error','Paquete no editado');
          });
      },
      async listDniType(){
        await dni.list().then((response) => {
          Object.entries(response.data.data).forEach((entry) => {
            const [key, value] = entry;
            this.DniType.push( { value: value.uuid, text: value.name } );
          });
        }).catch((error) => {
   
        });
      },
      async pageChanged(page) {
        
        page = page == 1 ? 0 : page - 1 ;
  
        this.documents(page);
      },
      async departamentUser(){
        await departamentsUser.list({
          params:{
            'department_uuid' : this.$route.params.id,
          }
        }).then((response) => {
          Object.entries(response.data.data).forEach((entry) => {
            const [key, value] = entry;
            this.inquilinos.push( { code: value.uuid, label: `${value.user_uuid.name} ${value.user_uuid.last_name}` } );
          });
        }).catch((error) => {

        });
      }
      
    },
    beforeMount() {
      
    },
    mounted(){
      this.departamentUser();
      this.listDniType();
      this.documents();
    },
  };
  </script>
  