//import axios from 'axios';
import http from "../../axios/axios_conf";
import api from "../../routers/routers";

class departamentsUser {
  assing(assing_user_data) {
    return http.post(api.departments_users.assing.endpoint, assing_user_data);
  }

  assignPrincipal(departmentUserUuid, data) {
    return http.put(`/department_users/${departmentUserUuid}`, data);
  }

  list(params) {
    return http.get(api.departments_users.list.endpoint, params);
  }

  assing_delete(assing_delete_data) {
    return http.delete(
      api.departments_users.delete.endpoint+"/"+assing_delete_data
    );
  }
}

export default new departamentsUser();
